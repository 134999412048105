<template>
  <div>
    <!-- this page is only temporary till embed can be updated -->
  </div>
</template>

<script>
export default {
  mounted() {
    this.$router.push({name: 'commission-settings'});
  }
}
</script>

<style lang="scss" scoped>

</style>
